import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import './HowItWorksSection.scss'
import ImageSection from '../ImageSection/ImageSection'

const HowItWorksSection = ({ onlySteps }) => {
  const data = useStaticQuery(graphql`{
  placeholderImage: file(relativePath: {eq: "howitworks-image.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, width: 650, layout: CONSTRAINED)
    }
  }
}
`)

  const steps = [
    {
      number: '01',
      title: 'Krok pierwszy',
      text:
        'Pracownik loguje się do systemu z użyciem karty lub kodu, a następnie uzyskuje dostęp do artykułów.',
    },
    {
      number: '02',
      title: 'Krok drugi',
      text:
        'Na ekranie automatu wyświetlone zostają produkty wraz z opisami i zdjęciami. Pracownik wybiera asortyment dostępny w ramach jego uprawnień.',
    },
    {
      number: '03',
      title: 'Krok trzeci',
      text: 'Pobranie wybranego produktu ze wskazanej alokacji automatu vendingowego.',
    },
  ]

  const getElement = (element, index) => (
    <div key={index} className="howitworksSection__element">
      <div
        className="howitworksSection__element-number"
        dangerouslySetInnerHTML={{ __html: element.number }}
      />
      <div
        className="howitworksSection__element-title"
        dangerouslySetInnerHTML={{ __html: element.title }}
      />
      <div
        className="howitworksSection__element-text"
        dangerouslySetInnerHTML={{ __html: element.text }}
      />
    </div>
  )

  return (
    <div className="howitworksSection">
      <div className="container container--howitworksSection">
        {!onlySteps ? (
          <>
            <h4 className="howitworksSection__pretitle">AUTOMATY VENDINGOWE</h4>
            <h3 className="howitworksSection__title">JAK TO DZIAŁA?</h3>
            <div className="howitworksSection__image">
              <ImageSection
                alt="JAK TO DZIAŁA?"
                imageFluid={data.placeholderImage.childImageSharp.gatsbyImageData}
              />
            </div>
          </>
        ) : (
          ''
        )}
        <div className="howitworksSection__content">{steps.map(getElement)}</div>
      </div>
    </div>
  );
}

HowItWorksSection.propTypes = {
  onlySteps: PropTypes.bool,
}

HowItWorksSection.defaultProps = {
  onlySteps: false,
}

export default HowItWorksSection
