import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import './WhoWeAreSection.scss'
import ImageSection from '../ImageSection/ImageSection'

const WhoWeAreSection = () => {
  const data = useStaticQuery(graphql`{
  placeholderImage: file(relativePath: {eq: "whoweare-image.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, width: 420, layout: CONSTRAINED)
    }
  }
}
`)

  return (
    <div className="whoweareSection">
      <div className="whoweareSection__column whoweareSection__column--left">
        <h4 className="whoweareSection__pretitle">O NAS</h4>
        <h3 className="whoweareSection__title">Kim jesteśmy?</h3>
        <div className="whoweareSection__context">
          Zajmujemy się sprzedażą odzieży roboczej oraz środków ochrony indywidualnej za
          pośrednictwem automatów wydających. Dzięki naszym usługom Twoi pracownicy zyskają
          możliwość pobierania niezbędnych produktów 24 godziny na dobę. Oferujemy nie tylko szeroką
          gamę wysokojakościowych produktów z zakresu BHP, ale także wynajem automatów wydających,
          serwisowanie i czyszczenie odzieży roboczej.
        </div>
        <Link to="/o-nas/" className="whoweareSection__more-link">
          Więcej...
        </Link>
      </div>
      <div className="whoweareSection__column whoweareSection__column--right">
        <ImageSection alt="Kim jestesmy" imageFluid={data.placeholderImage.childImageSharp.gatsbyImageData} />
      </div>
    </div>
  );
}

export default WhoWeAreSection
