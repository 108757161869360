/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './RedBar.scss'

const scrollToDataId = (id, correction) => {
  const cumulativeOffsetTop = el => {
    let top = 0
    do {
      top += el.offsetTop || 0
      el = el.offsetParent
    } while (el)

    return top
  }

  if (typeof document !== 'undefined') {
    const element = document.querySelector(`[data-id="${id}"]`)
    if (element) {
      window.scrollTo({
        top: cumulativeOffsetTop(element) - correction,
        behavior: 'smooth',
      })
    }
  }
}

const RedBar = ({ textHtml, id }) => {
  const [isClose, close] = useState(true)

  useEffect(() => {
    typeof sessionStorage !== 'undefined' && close(!!sessionStorage.getItem(`RedBar_${id}`))
  })

  const handleClick = () => {
    scrollToDataId('contactForm', 25)
    close(true)
    typeof sessionStorage !== 'undefined' && sessionStorage.setItem(`RedBar_${id}`, 'closed')
  }

  return (
    <div className={`redbar__wrapper ${isClose ? 'closed' : ''}`}>
      <div className="redbar__text" dangerouslySetInnerHTML={{ __html: textHtml }} />
      <button type="button" className="redbar__btn" onClick={handleClick}>
        Kontakt
      </button>
    </div>
  )
}

RedBar.propTypes = {
  textHtml: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default RedBar
