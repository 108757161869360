import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import './PartnersSection.scss'
import BackgroundImage from 'gatsby-background-image'
import { getImage as gpiGetImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import ImageSection from '../ImageSection/ImageSection'

const PartnersSection = () => {
  const data = useStaticQuery(graphql`
    {
      placeholderImage: file(relativePath: { eq: "partners-image.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH)
        }
      }
      img1: file(relativePath: { eq: "clients/1.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 130, layout: CONSTRAINED)
        }
      }
      img2: file(relativePath: { eq: "clients/2.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 130, layout: CONSTRAINED)
        }
      }
      img3: file(relativePath: { eq: "clients/3.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 130, layout: CONSTRAINED)
        }
      }
      img4: file(relativePath: { eq: "clients/4.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 130, layout: CONSTRAINED)
        }
      }
      img5: file(relativePath: { eq: "clients/5.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 130, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <BackgroundImage
      Tag="div"
      className="partnersSection"
      {...convertToBgImage(gpiGetImage(data.placeholderImage))}
      classId="partnersSection"
    >
      <div className="partnersSection__mask">
        <div className="container container--partnersSection">
          <h3 className="partnersSection__title">NASI KLIENCI</h3>
          <div className="partnersSection__list">
            <div className="partnerSection__square">
              <ImageSection
                sectionClass="partnerSection__image"
                alt="Grupa Marat"
                imageFluid={data.img1.childImageSharp.gatsbyImageData}
              />
            </div>
            <div className="partnerSection__square">
              <ImageSection
                sectionClass="partnerSection__image"
                alt="FedEx Express"
                imageFluid={data.img2.childImageSharp.gatsbyImageData}
              />
            </div>
            <div className="partnerSection__square">
              <ImageSection
                sectionClass="partnerSection__image"
                alt="Inżbud"
                imageFluid={data.img3.childImageSharp.gatsbyImageData}
              />
            </div>
            <div className="partnerSection__square">
              <ImageSection
                sectionClass="partnerSection__image"
                alt="Nomecon"
                imageFluid={data.img4.childImageSharp.gatsbyImageData}
              />
            </div>
            <div className="partnerSection__square">
              <ImageSection
                sectionClass="partnerSection__image"
                alt="Purmo Group"
                imageFluid={data.img5.childImageSharp.gatsbyImageData}
              />
            </div>
          </div>
          <span className="partnersSection__text">
            „Od początku naszej współpracy, nastawienie firmy LIPUS SAFETY cechuje się silną
            orientacją na zapewnienie wysokiej jakości świadczonych usług, dużą elastycznością oraz
            dostosowaniem do naszych potrzeb i bardzo szybką reakcją na wszelkie pojawiające się z
            naszej strony oczekiwania.”
          </span>
          <span className="partnersSection__name">NOMECON</span>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default PartnersSection
