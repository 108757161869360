import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import WhoWeAreSection from '../components/_shared/WhoWeAreSection/WhoWeAreSection'
import AdvantagesSection from '../components/_shared/AdvantagesSection/AdvantagesSection'
import PartnersSection from '../components/_shared/PartnersSection/PartnersSection'
import HowItWorksSection from '../components/_shared/HowItWorksSection/HowItWorksSection'
import ContactFormSection from '../components/_shared/ContactFormSection/ContactFormSection'
import RedBar from '../components/_shared/RedBar/RedBar'

const HomePage = () => {
  const data = useStaticQuery(graphql`
    {
      placeholderImage: file(relativePath: { eq: "banner-image.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Layout
      pageTitle="Jesteśmy dla was"
      pageTitleDesc="Lipus Safety – kompleksowa dystrybucja środków ochrony indywidualnej z wykorzystaniem automatów vendingowych"
      bgImage={data.placeholderImage}
    >
      <div className="container container--page">
        <WhoWeAreSection />
      </div>
      <AdvantagesSection />
      <div className="container container--page">
        <HowItWorksSection />
      </div>
      <PartnersSection />
      <ContactFormSection />
      <RedBar
        id="index"
        textHtml='Potrzebujesz więcej informacji o rękawicach <a href="https://www.wr-industry.de/" title="https://www.wr-industry.de/">W+R</a>®?'
      />
    </Layout>
  )
}

export default HomePage
