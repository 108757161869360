import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from "gatsby-plugin-image";
import './ImageSection.scss'

const ImageSection = ({ sectionClass, alt, imageFluid }) =>
  imageFluid && (
    <GatsbyImage
      image={imageFluid}
      Tag="div"
      alt={alt}
      className={sectionClass}
      classId={sectionClass} />
  )

ImageSection.propTypes = {
  sectionClass: PropTypes.string,
  alt: PropTypes.string,
  imageFluid: PropTypes.shape(),
}

ImageSection.defaultProps = {
  sectionClass: 'imageSection',
  alt: 'zdjecie',
  imageFluid: null,
}

export default ImageSection
